import React from 'react';
import './HomeScreen.css';

const HomeScreen: React.FC = () => {
    return (
        <div className="home-screen">

        </div>
    );
};

export default HomeScreen;
